<template>
  <div>
    <div class="connect_head_wrapper">
      <div class="container">
        <div class="connect_head_title">
          贴心服务 及时响应
        </div>
        <div class="connect_des">
          7*12小时：专家坐席团队提供7*12小时的远程支持服务，<br> 及时响应您的问题，快速解决使用难题。
        </div>
      </div>
    </div>
    <div class="main_wrapper">
      <div class="container">
        <div class="flex">
          <div class="left_wrapper">
            <div class="left_title">联系我们：
            </div>
            <div class="left_address">
              成都酒搭网络科技有限公司
            </div>
            <div class="left_address">
              地址：四川省成都市锦江区驿都西路316号绿地中心·468锦峰
            </div>
            <div class="left_address">
              服务热线 : 138 8056 3034
            </div>
            <div class="left_address left_address_online">
              在线咨询：
            </div>
            <div class="left_qr">
              <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4253cf16387702299482452009/f0.png" alt="">
            </div>
          </div>
          <div class="right_wrapper">
            <div id="map_contaier" ref="mapContaier">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src=""></script>
<script>
export default {
  mounted() {
    var map = new AMap.Map(this.$refs.mapContaier, {
      center: [104.154945, 30.605967],
      zoom: 11,
    });
    var marker = new AMap.Marker({
      position: new AMap.LngLat(104.154945, 30.605967), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    });
    map.add(marker);
  },
};
</script>

<style lang="less" scoped>
.connect_head_wrapper {
  background-image: url("http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4701f75f387702299482659036/f0.png");
  height: 218px;
  background-size: cover;
  padding-top: 136px;
  margin-bottom: 120px;
  .connect_head_title {
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    margin-bottom: 42px;
  }
  .connect_des {
    font-size: 20px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }
}
.main_wrapper {
  margin-bottom: 135px;
  .left_wrapper {
    flex: 1;
    .left_title {
      font-size: 36px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #317bff;
      margin-bottom: 40px;
    }

    .left_address {
      font-size: 20px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
    }

    .left_address_online {
      display: none;
    }

    img {
      width: 200px;
      height: 200px;
    }
  }
  #map_contaier {
    width: 500px;
    height: 400px;
  }
}

@media all and (max-width: 1080px) {
  .connect_head_wrapper {
    height: 320px;
    padding: 80px 0 0 10px;
    background-image: url("http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/38a4bf70387702299482002354/f0.png");
    .connect_head_title {
      font-size: 36px;
      color: #fff;
    }
    .connect_des {
      font-size: 16px;
      color: #fff;
    }
  }
  .main_wrapper {
    .left_wrapper {
      padding: 0 10px;
      margin-bottom: 100px;
      .left_title {
        font-size: 36px;
      }
      .left_address {
        font-size: 16px;
        line-height: 26px;
      }
      .left_address_online {
        display: block;
      }
    }
    .right_wrapper {
      padding: 0 10px;
    }

    #map_contaier {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
